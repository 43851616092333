import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { PreRegister } from '@/wms/domain/preRegister/PreRegister';
import { PreRegisterRepository } from '@/wms/domain/preRegister/PreRegisterRepository';
import { PreRegisterForTraceabilityPanel } from '@/wms/domain/preRegister/PreRegisterForTraceabilityPanel';
import { PreRegisterForDashboard } from '@/wms/domain/preRegister/PreRegisterForDashboard';
import { DashboardAdministrationFilters } from '@/filters/domain/Filters';

@Injectable()
export class PreRegisterServices implements PreRegisterRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_PRE_REGISTER_SAVE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_SAVE;
  private readonly API_PRE_REGISTER_FINDALL: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FINDALL;
  private readonly API_PRE_REGISTER_FINDALL_BULKS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FINDALL_BULKS;
  private readonly API_PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE;
  private readonly API_PRE_REGISTER_FINDALL_BY_DATE_AND_LIKE_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FINDALL_BY_DATE_AND_LIKE_TYPE;
  private readonly API_PRE_REGISTER_FIND_BY_PK: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FIND_BY_PK;
  private readonly API_PRE_REGISTER_UPDATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_UPDATE;
  private readonly API_PRE_REGISTER_CHANGE_TYPE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_CHANGE_TYPE;
  private readonly API_PRE_REGISTER_CREATE_REPORT: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_CREATE_REPORT;
  private readonly API_PRE_REGISTER_FIND_FOR_TRACEABILITY: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FIND_FOR_TRACEABILITY;
  private readonly API_PRE_REGISTER_FIND_FOR_DASHBOARD: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FIND_FOR_DASHBOARD;
  private readonly API_PRE_REGISTER_FIND_SHIPPERS: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_PRE_REGISTER_FIND_SHIPPERS;

  async findAll(module: string): Promise<PreRegister[]> {
    try {
      return await http.get<PreRegister[]>(`${this.API_PRE_REGISTER_FINDALL}/${module}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByPk(payload: { type: string; number: number }): Promise<PreRegister> {
    try {
      return await http.get<PreRegister>(
        `${this.API_PRE_REGISTER_FIND_BY_PK}type=${payload.type}&number=${payload.number}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async save(payload: PreRegister): Promise<PreRegister> {
    try {
      return await http.post<PreRegister>(this.API_PRE_REGISTER_SAVE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async update(payload: PreRegister): Promise<any> {
    try {
      return await http.post<PreRegister>(this.API_PRE_REGISTER_UPDATE, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findAllWithBulks(module: string): Promise<PreRegister[]> {
    try {
      return await http.get<PreRegister[]>(`${this.API_PRE_REGISTER_FINDALL_BULKS}/${module}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findByTypeAndDate(payload: { to: string; from: string; type: string }): Promise<PreRegister[]> {
    try {
      return await http.get<PreRegister[]>(
        `${this.API_PRE_REGISTER_FINDALL_BY_DATE_AND_TYPE}/${payload.type}/${payload.from}/${payload.to}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  async findByLikeTypeAndDate(payload: { to: string; from: string; type: string }): Promise<PreRegister[]> {
    try {
      return await http.get<PreRegister[]>(
        `${this.API_PRE_REGISTER_FINDALL_BY_DATE_AND_LIKE_TYPE}/${payload.type}/${payload.from}/${payload.to}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  changeType({ payload, newType }: { payload: PreRegister; newType: string }): Promise<PreRegister> {
    try {
      return http._put<PreRegister>(`${this.API_PRE_REGISTER_CHANGE_TYPE}newType=${newType}`, JSON.stringify(payload));
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async createReport({ report, type, number }: { report: string; type: string; number: number }): Promise<string> {
    try {
      const response = await http.getFile<Blob>(
        this.API_PRE_REGISTER_CREATE_REPORT + `number=${number}&reportType=${report}&type=${type}`,
        'pdf'
      );

      const buffer = await response.arrayBuffer();

      const blob = new Blob([buffer], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      return url;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
  findForTraceabilty(payload: {
    dateFrom: string;
    dateTo: string;
    type: string;
  }): Promise<PreRegisterForTraceabilityPanel[]> {
    try {
      return http.get<PreRegisterForTraceabilityPanel[]>(
        `${this.API_PRE_REGISTER_FIND_FOR_TRACEABILITY}dateFrom=${payload.dateFrom}&dateTo=${payload.dateTo}&type=${payload.type}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  findForDashboard(payload: DashboardAdministrationFilters): Promise<PreRegisterForDashboard[]> {
    try {
      let queryParams = `dateFrom=${payload.initialDate}&dateTo=${payload.dueDate}&type=${payload?.type?.value}`;

      if (payload.commodity) {
        // eslint-disable-next-line no-console
        console.log(payload.commodity);
        queryParams = queryParams.concat(`&commodity=${payload.commodity.id}`);
      }

      if (payload.store.inf != '') {
        queryParams = queryParams.concat(`&storeInf=${payload.store.inf}`);
      }

      if (payload.store.sup != '') {
        queryParams = queryParams.concat(`&storeSup=${payload.store.sup}`);
      }

      if (payload.operationNumber != '') {
        queryParams = queryParams.concat(`&operationNumber=${payload.operationNumber}`);
      }

      if (payload.masterNumber != '') {
        queryParams = queryParams.concat(`&masterNumber=${payload.masterNumber}`);
      }

      if (payload.shipper) {
        queryParams = queryParams.concat(`&shipper=${payload.shipper}`);
      }

      if (payload.consignee) {
        queryParams = queryParams.concat(`&consignee=${payload.consignee}`);
      }

      if (payload.agent) {
        queryParams = queryParams.concat(`&agent=${payload.agent.code}`);
      }

      if (payload.carrier) {
        queryParams = queryParams.concat(`&carrier=${payload.carrier.code}`);
      }

      if (payload.state) {
        queryParams = queryParams.concat(`&state=${payload.state.value}`);
      }

      if (payload.origin) {
        queryParams = queryParams.concat(`&origin=${payload.origin.city}`);
      }

      if (payload.destination) {
        queryParams = queryParams.concat(`&destination=${payload.destination.city}`);
      }

      return http.get<PreRegisterForDashboard[]>(`${this.API_PRE_REGISTER_FIND_FOR_DASHBOARD}${queryParams}`);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  async findShippersByOperation(operation: { number: number; type: string }): Promise<string[]> {
    try {
      return await http.get<string[]>(
        `${this.API_PRE_REGISTER_FIND_SHIPPERS}?number=${operation.number}&type=${operation.type}`
      );
    } catch (error) {
      throw new Error(`${error}`);
    }
  }
}
