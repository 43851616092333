import { http } from '@/core/http/HttpFetch';
import { Injectable } from '@/core/di/Injectable';
import { localStorageModule } from '@/core/config/ConfigurationServer';
import { Report } from '@/wms/domain/reports/ReportConfig';
import { ReportConfigRepository } from '@/wms/domain/reports/ReportConfigRepository';
import { ReportFilters } from '@/wms/domain/reports/ReportFilters';

@Injectable()
export class ReportConfigService implements ReportConfigRepository {
  private readonly API_BASE_URI: string = localStorageModule().transactions.host.API_BASE_URI;
  private readonly API_REPORT_CONFIG: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_REPORT_CONFIG;
  private readonly API_REPORT_CONFIG_GENERATE: string =
    this.API_BASE_URI + localStorageModule().transactions.routes.API_REPORT_GENERATE;

  /**
   * @returns Un array con todas las configuraciones de reportes
   * @throws Error al buscar configuraciones de reportes
   * @description Busca todas las configuraciones de reportes
   * @author Mauricio Cantillo
   */
  async findAll(): Promise<Report[]> {
    try {
      return await http.get<Report[]>(this.API_REPORT_CONFIG);
    } catch (error) {
      throw new Error(`Error al buscar configuraciones de reportes: ${error}`);
    }
  }

  /**
   * @param report configuración de reporte a actualizar
   * @returns configuración de reporte actualizada
   * @throws Error al actualizar configuración de reporte
   * @description Actualiza configuración de reporte
   * @author Mauricio Cantillo
   */
  async update(report: Report): Promise<Report> {
    try {
      return await http.put<Report>(this.API_REPORT_CONFIG, JSON.stringify(report));
    } catch (error) {
      throw new Error(`Error al actualizar configuración de reporte: ${error}`);
    }
  }

  /**
   * @param id Id de la configuración de reporte
   * @returns Nada
   * @throws Error al borrar configuración de reporte
   * @description Borra una configuración de reporte por su id
   * @author Mauricio Cantillo
   */
  delete(id: string): Promise<void> {
    try {
      return http.singleDelete<void>(`${this.API_REPORT_CONFIG}id=${id}`);
    } catch (error) {
      throw new Error(`Error al borrar configuración de reporte, ${error}`);
    }
  }

  /**
   * @param id Id de la configuración de reporte
   * @returns La configuración de reporte encontrada
   * @throws Error al buscar configuración de reporte
   * @description Busca una configuración de reporte por su id
   * @author Mauricio Cantillo
   */

  findById(id: string): Promise<Report> {
    try {
      return http.get<Report>(`${this.API_REPORT_CONFIG}id=${id}`);
    } catch (error) {
      throw new Error(`Error al buscar configuración de reporte: ${error}`);
    }
  }

  /**
   * @param type Tipo de reporte a generar
   * @returns Un array con los datos del reporte
   * @throws Error al generar el reporte
   * @description Genera un reporte con los datos solicitados
   * @author Mauricio Cantillo
   */
  async generateReport(type: string, filters: ReportFilters): Promise<string> {
    try {
      const data = await http.postFile<Blob>(`${this.API_REPORT_CONFIG_GENERATE}type=${type}`, JSON.stringify(filters));

      const url = URL.createObjectURL(data);
      return url;
    } catch (error) {
      throw new Error(`Error al generar reporte: ${error}`);
    }
  }
}
